import { Instagram } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { InstagramEmbed } from "react-social-media-embed";

export default () => {
  return (
    <>
      <Box sx={{ margin: "5vw" }}>
        <Stack justifyContent={"center"} flexDirection="row" flexWrap={"wrap"}>
          <Box>
            <InstagramEmbed
              url="https://www.instagram.com/p/C713-z-AAOa/"
              captioned
            />
          </Box>
          <Box>
            <InstagramEmbed
              url="https://www.instagram.com/p/C7xrbkagkkK/"
              captioned
            />
          </Box>
          <Box>
            <InstagramEmbed
              url="https://www.instagram.com/p/C7ckDeovR71/"
              captioned
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
